import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
export function isvalidPhone(phone) {
  var reg = /^1[3-9][0-9]\d{8}$/;
  return reg.test(phone);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}

/**
 * 只能输入正整数含0
 * @param num
 */
export function enterPositiveNum(num) {
  return Number(num.replace(/\D/g, ''));
}

/**
 * 金额校验
 * @param money
 */
export function validateMoney(money, targetMoney) {
  var decimalCount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var targetMoneyMsg = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '输入金额已超过订单金额！';
  if (money.length > 9 && decimalCount === 2) {
    this.$message({
      type: 'error',
      message: '输入金额已超出最大限制, 请重新输入！'
    });
    return '0.00';
  }
  if (Number(targetMoney) && Number(money) > Number(targetMoney)) {
    this.$message({
      type: 'error',
      message: targetMoneyMsg
    });
    return targetMoney;
  }
  money = money.toString();
  money = money.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
  money = money.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
  money = money.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  if (decimalCount === 2) {
    money = money.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  } else if (decimalCount === 1) {
    money = money.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3'); // 只能输入一个小数
  }
  return money;
}

/**
 * 金额校验补0
 * @param money
 */
export function validateMoneyZero(money) {
  return Number(money).toFixed(2).toString();
}

/**
 * 是否合法IP地址
 * @param rule
 * @param value
 * @param callback
 */
export function validateIP(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的IP地址'));
    } else {
      callback();
    }
  }
}

/* 是否手机号码或者固话*/
export function validatePhoneTwo(rule, value, callback) {
  var reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的电话号码或者固话号码'));
    } else {
      callback();
    }
  }
}

/* 是否固话*/
export function validateTelephone(rule, value, callback) {
  var reg = /0\d{2}-\d{7,8}/;
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的固话（格式：区号+号码,如010-1234567）'));
    } else {
      callback();
    }
  }
}

/* 是否手机号码*/
export var validatePhone = function validatePhone(rule, value, callback) {
  var reg = /^[1][3-9][0-9]{9}$/;
  if (value === '' || value === undefined || value == null) {
    callback && callback();
  } else {
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的电话号码'));
    } else {
      callback();
    }
  }
};

/* 是否QQ号*/
export var validateQQ = function validateQQ(rule, value, callback) {
  var reg = /^[1-9][0-9]{4,9}$/g;
  if (value === '' || value === undefined || value == null) {
    callback && callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error('请输入正确的QQ号。'));
    } else {
      callback();
    }
  }
};

/* 校验微信号（字母&手机号&QQ）*/
export var validateWxchat = function validateWxchat(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (isNaN(value)) {
      var reg = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
      if (!reg.test(value)) {
        callback(new Error('请输入正确的微信号。'));
      } else {
        callback();
      }
    } else {
      var tel = /^[1][3-9][0-9]{9}$/;
      if (!tel.test(value) && value.length === 11) {
        callback(new Error('请输入正确的微信号（手机）。'));
      } else if (tel.test(value) && value.length === 11) {
        callback();
      } else {
        validateQQ(undefined, value, callback);
      }
    }
  }
};
export function isvalidIdNo(idNo) {
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return reg.test(idNo);
}

/* 是否身份证号码*/
export function validateIdNo(rule, value, callback) {
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的身份证号码'));
    } else {
      callback();
    }
  }
}

/*  不能输入空格 */
export function validateSpace(str) {
  return str.replace(/^ +| +$/g, '').trim();
}

/**
 * 数字转中文
 * @param money
 */
export function convertToChinaNum(num) {
  var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']; // 可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return '零';
  }
  var english = num.toString().split('');
  var result = '';
  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i; // 倒序排列设值
    result = arr2[i] + result;
    var arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
  // 合并中间多个零为一个零
  result = result.replace(/零+/g, '零');
  // 将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
  // 将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿');
  // 移除末尾的零
  result = result.replace(/零+$/, '');
  // 将【零一十】换成【零十】
  // result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, '十');
  return result;
}