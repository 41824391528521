import "/var/jenkins_home/workspace/crm-admin-uat/node_modules/core-js/modules/es.array.iterator.js";
import "/var/jenkins_home/workspace/crm-admin-uat/node_modules/core-js/modules/es.promise.js";
import "/var/jenkins_home/workspace/crm-admin-uat/node_modules/core-js/modules/es.object.assign.js";
import "/var/jenkins_home/workspace/crm-admin-uat/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

// 数据字典
import dict from './components/Dict';

// 权限指令
import checkPer from '@/utils/permission';
import permission from './components/Permission';
import './assets/styles/element-variables.scss';
// global css
import './assets/styles/index.scss';

// 代码高亮
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';
import App from './App';
import store from './store';
import router from './router/routers';
import './assets/icons'; // icon
import './router/index'; // permission control
import 'echarts-gl';

// 回访闹钟
import Clock from './components/clock/index';
import ElTreeGrid from 'element-tree-grid';

// vue2
import WujieVue from 'wujie-vue2';
import { startApp } from 'wujie';
var setupApp = WujieVue.setupApp,
  preloadApp = WujieVue.preloadApp;
Vue.component(ElTreeGrid.name, ElTreeGrid);
// 注册回访闹钟
Vue.prototype.$alarm = Clock.install;
Vue.use(checkPer);
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
});
Vue.use(WujieVue);
setupApp({
  name: 'vue3',
  url: 'http://127.0.0.1:5173/',
  exec: true,
  el: '#vue3',
  sync: true,
  plugins: [{
    patchElementHook: function patchElementHook(element, iframeWindow) {
      if (element.nodeName === 'STYLE') {
        element.insertAdjacentElement = function (_position, ele) {
          iframeWindow.document.head.appendChild(ele);
        };
      }
    }
  }]
});
preloadApp({
  name: 'vue3'
});
startApp({
  name: 'vue3'
});
Vue.config.productionTip = false;
Vue.mixin({
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (!['CourseQuestionList'].includes(to.name) && from.name === 'CourseQuestionDetail') {
      from.meta.keepAlive = true;
    } else {
      if (this.$vnode && this.$vnode.data.keepAlive && from.name === 'CourseQuestionDetail' && ['CourseQuestionList'].includes(to.name)) {
        if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache) {
          if (this.$vnode.componentOptions) {
            var key = this.$vnode.key == null ? this.$vnode.componentOptions.Ctor.cid : this.$vnode.key;
            var cache = this.$vnode.parent.componentInstance.cache;
            var keys = this.$vnode.parent.componentInstance.keys;
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key.toString());
                if (index > -1) {
                  keys.splice(index, 1);
                }
              }
              delete cache[key];
            }
          }
        }
        from.meta.keepAlive = true;
      }
    }
    next();
  }
});
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});